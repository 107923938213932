import React from "react";
import Section from "../components/Section";

import { BsTrophy } from "react-icons/bs"
import { RiFilePaper2Fill } from "react-icons/ri"

export default function Reflections() {
  return (
    <React.Fragment>
      <Section id="achievements" title="Reflections">
        <p>
        As a software development student, I have come to realize that my field is constantly evolving, and keeping up with new technologies and trends is essential to stay relevant and competitive. Through my coursework and projects, I have learned not only technical skills but also important soft skills such as collaboration, communication, and critical thinking.

        One of the most valuable lessons I have learned is the importance of attention to detail. In software development, even the smallest error can have significant consequences. Therefore, I have learned to be meticulous in my work, carefully reviewing my code and testing thoroughly to ensure that my programs run smoothly and efficiently.

        Another important aspect of my learning journey has been the collaborative nature of software development. Working with other students on group projects has allowed me to develop my communication and teamwork skills, learn from others, and gain a broader perspective on problem-solving.

        As I reflect on my progress as a software development student, I am proud of the projects I have completed and the skills I have acquired. However, I am also aware that there is always room for improvement, and I am eager to continue learning and growing as a software development. I am excited to explore new technologies and pursue new challenges, knowing that my journey in this field is just beginning.
        </p>
        {/* <ul className="fa-ul mb-0">
          <div>
            <span className="fa-li p-2">
              <i className="fas fa-trophy text-warning"><BsTrophy /></i>
            </span>
            <a href="https://drive.google.com/file/d/1AffY67ZamCGlrocrvHkmAT9NEYu3GNHd/view?usp=sharing">
              Google Hash Code 2021
            </a>
          </div>
          <div>
            <span className="fa-li p-2">
              <i className="fas fa-trophy text-warning"><BsTrophy /></i>
            </span>
            <a href="https://drive.google.com/file/d/13RZHo6PMYYJ2C5r2QT5BPARK_DWS9Wcj/view?usp=sharing">
              Google Code Jam 2021
            </a>
          </div>
        </ul> */}
      </Section>
    </React.Fragment>
  );
}
