import React from "react";
import Section from "../components/Section";

export default function Experience() {
  return (
    <Section id="experience" title="Experience">
      <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
        <div className="flex-grow-1">
          <h3 className="mb-0">C++ customer service app</h3>
          <div className="subheading mb-3">Skillablers ~ Skillfully!</div>
          <p>
            <strong>Roles/Responsibilities</strong><br />
            <li>Responsible to handle the the structure of the program.</li>
            <li>Implement new features to the program.</li>
            <li>Make the program very efficient.</li>
            <li>Implement many alternative solution for a problem.</li>
          </p>
        </div>
        <div className="flex-shrink-0">
          <span className="text-primary">July 2021 - August 2021 </span>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
        <div className="flex-grow-1">
          <h3 className="mb-0">Piscine</h3>
          <div className="subheading mb-3">42 Abudhabi</div>
          <p>
            <li>Finishing new and hard peojects in short amount of time</li>
            <li>Meeting and helping peers.</li>
            <li>Taking exams everyweek.</li>
            <li>Implement many alternative solution for a problem.</li>
          </p>
        </div>
        <div className="flex-shrink-0">
          <span className="text-primary">May 2021 - June 2021</span>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
        <div className="flex-grow-1">
          <h3 className="mb-0">Data Entry</h3>
          <div className="subheading mb-3">St Matthew's library</div>
          <p>
            <li>Accurately inputted an average of 500 data points per day into the database, maintaining a 99% accuracy rate.</li>
            <li>Demonstrated attention to detail, accuracy, and efficiency in all tasks, resulting a 20% increase in productivity.</li>
            <li>Keeping records of completed work and progress made towards goals.</li>
            <li>Ensuring data integrity by checking for errors and inconsistencies in the information entered.</li>
            <li>Assisting in other related tasks or projects as needed by the team or supervisor.</li>
          </p>
        </div>
        <div className="flex-shrink-0">
          <span className="text-primary">November 2019 - July 2020</span>
        </div>
      </div>

      {/* <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
        <div className="flex-grow-1">
          <h3 className="mb-0">Co-founder</h3>
          <div className="subheading mb-3">Eyalna (startup)</div>
          <p>
              <li>Co-founded and established a startup focused on reducing kids screen time and organizing nearby events.</li>
              <li>Played a key role in shaping the startup's visual identity, brand guidelines, and overall design strategy.</li>
              <li>Collaborated closely with cross-functional teams, including product managers and developers, to ensure seamless implementation of design solutions.</li>
              <li>Continuously stayed updated with industry trends and best practices, incorporating the latest design techniques and tools to enhance product aesthetics and usability.</li>
          </p>
        </div>
        <div className="flex-shrink-0">
          <span className="text-primary">February 2022 - Present</span>
        </div>
      </div> */}
    </Section>
  );
}
