import React from "react";
import Section from "../components/Section";

export default function Education() {
  return (
    <Section id="education" title="Education">
      {/* <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
        <div className="flex-grow-1">
          <h3 className="mb-0">Adama Science And Technology University</h3>
          <div className="subheading mb-3">Material Engineering</div>
          <div>Composition of materials, Alloys, material choosing</div>
        </div>
        <div className="flex-shrink-0">
          <span className="text-primary">september 2019 - on break</span>
        </div>
      </div> */}
      <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
        <div className="flex-grow-1">
          <h3 className="mb-0">42 Abu Dhabi</h3>
          <div className="subheading mb-3">Software development diploma</div>
          <div>Complete real world projects in C programming language and collaborate with peers to develop solutions to programming challenges</div>
          <p>C, C++, Shell, Git, GitHub</p>
        </div>
        <div className="flex-shrink-0">
          <span className="text-primary">Sep 2022 - Dec 2023 </span>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
        <div className="flex-grow-1">
          <h3 className="mb-0">Holy trinity cathedral school</h3>
          <div className="subheading mb-3">High school diploma</div>
          <div>Attend high school at Holy trinity cathedral school and got a Diploma</div>
        </div>
        <div className="flex-shrink-0">
          <span className="text-primary">June  2019</span>
        </div>
      </div>
    </Section>
  );
}
