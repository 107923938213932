import React from "react";
import Section from "../components/Section";

import { BsTrophy } from "react-icons/bs"
import { RiFilePaper2Fill } from "react-icons/ri"

export default function Achievements() {
  return (
    <React.Fragment>
      <Section id="achievements" title="Achievements &amp; Certifications">
        <ul className="fa-ul mb-0">
          <div>
            <span className="fa-li p-2">
              <i className="fas fa-trophy text-warning"><BsTrophy /></i>
            </span>
            <a href="#">
            Gained first place in a Hackathon organized by 42 Abu Dhabi students.
            </a>
          </div>
          <div>
            <span className="fa-li p-2">
              <i className="fas fa-trophy text-warning"><BsTrophy /></i>
            </span>
            <a href="#">
            Certificate for volunteering at GITEX 2022
            </a>
          </div>
          <div>
            <span className="fa-li p-2">
              <i className="fas fa-trophy text-warning"><BsTrophy /></i>
            </span>
            <a href="#">
            Gained third place in a Hackathon organized by 42 Abu Dhabi and Beacon Red().
            </a>
          </div>
          <div>
            <span className="fa-li p-2">
              <i className="fas fa-trophy text-warning"><BsTrophy /></i>
            </span>
            <a href="#">
            Gained first place in the Youth Challenge Techstars startup Hackathon.
            </a>
          </div>

          <div>
            <span className="fa-li p-2">
              <i className="fas fa-trophy text-warning"><BsTrophy /></i>
            </span>
            <a href="#">
            Gained second place in a Hackathon organized by 42 Abu Dhabi and Beacon Red.
            </a>
          </div>

          <div>
            <span className="fa-li p-2">
              <i className="fas fa-trophy text-warning"><BsTrophy /></i>
            </span>
            <a href="#">
            Certificate for completion of nestjs course from mind Luster.
            </a>
          </div>

          <div>
            <span className="fa-li p-2">
              <i className="fas fa-trophy text-warning"><BsTrophy /></i>
            </span>
            <a href="https://www.codewars.com/users/yoatan">
              6kyu Codewars
            </a>
          </div>
        </ul>
      </Section>
    </React.Fragment>
  );
}
