import TODO from "../assets/portfolio/todo.png"
import INTRA from "../assets/portfolio/42_intra.png"
import ALX from "../assets/portfolio/alx.png"
import PIPEX from "../assets/portfolio/pipex.png"
import FRACTOL from "../assets/portfolio/fract-ol.png"
import MINISHELL from "../assets/portfolio/minishell.png"
import FT_IRC from "../assets/portfolio/ft_irc.png"

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    img: MINISHELL,
    title: "42_minishell",
    desc: "A simple recreation of the bash we all use in out day to day life",
    demo: "https://github.com/yonatan46/42_minishell",
    source: "https://github.com/yonatan46/42_minishell",
  },
  {
    img: FRACTOL,
    title: "42_fract-ol",
    desc: "A beautiful image drawn by using the 42 library mlx,, along with the famous formula z=zn^2+1",
    demo: "https://github.com/yonatan46/42-fract-ol",
    source: "https://github.com/yonatan46/42-fract-ol",
  },
  {
    img: PIPEX,
    title: "42_pipex",
    desc: "A projects which impliment the bash's | in c program",
    demo: "https://github.com/yonatan46/42_pipex",
    source: "https://github.com/yonatan46/42_pipex",
  },
  {
    img: ALX,
    title: "boot-camp",
    desc: "Projects i finished from ALX bootcamp",
    demo: "https://github.com/yonatan46/alx-low_level_programming",
    source: "https://github.com/yonatan46/alx-low_level_programming",
  },
  {
    img: TODO,
    title: "TODO-APP",
    desc: "A simple todo applcation i create while practiving my skill on react js framework",
    demo: "https://todo-yonatan46.vercel.app/",
    source: "https://github.com/yonatan46/Todo",
  },
  {
    img: INTRA,
    title: "42_AD",
    desc: "A simple application we created for a hackathon @42abudhabi, modified intra with a new and easy to access features",
    demo: "https://github.com/Aztaro97/42AD",
    source: "https://github.com/Aztaro97/42AD",
  },
  
  {
    img: FT_IRC,
    title: "FT_IRC",
    desc: "An internet relay chat system we created as one of the projects from 42abudhabi, we created the chating system from scrach and it's fully functional!",
    demo: "https://github.com/ChineduGboof/ft_irc",
    source: "https://github.com/ChineduGboof/ft_irc",
  },
  

]
