import React from "react"
import Section from "../components/Section"
import ResumePDF from "../assets/YonatanResume.pdf"//cv
import SGCartoon from "../assets/sagnik_cartoon.png"
import { FaFacebookF, FaGithub, FaLinkedinIn, FaTwitter, FaInstagram } from "react-icons/fa"

const SocialIcon = ({ href, icon }) => {
  return (
    <a
      className="social-icon"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {icon}
    </a>
  )
}

export default function Home() {
  const icons = [
    {
      href: "https://www.linkedin.com/in/yonatan46/",
      icon: <FaLinkedinIn />,
    },
    {
      href: "https://github.com/yonatan46",
      icon: <FaGithub />,
    },
    {
      href: "https://www.instagram.com/yo_nitan",
      icon: <FaInstagram />,
    },
  ]

  return (
    <Section id="about">
      <div className='row justify-content-between'>
        <div className='col-md-6 order-last order-lg-first' data-aos='fade-up'>
          <h1 className="mb-0">
            Yonatan
            <span className="text-primary" style={{paddingLeft: '2vw'}}>Moges</span>
          </h1>
          <div className="subheading mb-3">
            <span style={{paddingRight: '0.3vw'}}>
              Impossible is nothing!
            </span>
            <span style={{paddingRight: '0.3vw'}}>
              ·
            </span>
            <a href="mailto:yonatankefyalew65@gmail.com" className='home-mail'>
              yonatankefyalew65@gmail.com
            </a>
          </div>
        </div>
        <div className='col-md-3 mb-5 mb-lg-0' data-aos='fade-up'>
          <img
            src={SGCartoon}
            alt="Sagnik Cartoon"
            style={{height: '40vh', width: '40vh'}}
          />
        </div>
      </div>

      <p className="lead mb-4">
      A recent graduate from 42 Abu Dhabi, I bring a commitment to continuous learning and growth. Dedicated and motivated, with a
strong interest in network security, data sanitization, and software development. I am passionate about staying up-to-date with the
latest industry trends and advancements. With a solid foundation in programming languages such as C, C++, and JavaScript, as
well as web development technologies like HTML, CSS, and JS, I am well-equipped to take on any challenge in the field of
software engineering. Demonstrating exceptional problem-solving skills and attention to detail through coursework and projects, I
am always seeking new opportunities to enhance my knowledge and expertise. With a strong work ethic and a collaborative spirit,
I am confident in my ability to make meaningful contributions to any team and achieve success in my career.
      </p>

      <div className="btn-group mb-5">
        <button
          className="btn btn-primary btn-custom py-2 px-5 CV-Button"
          type="submit"
          onClick={() => window.open(ResumePDF)}
        >
          Download CV
        </button>
      </div>

      <div className="social-icons">
        {icons.map(({ href, icon }, index) => (
          <SocialIcon href={href} icon={icon} key={index} />
        ))}
      </div>
    </Section>
  )
}
